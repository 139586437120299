<template>
    <v-menu 
        :close-on-content-click="false" 
        v-model="menuOpen" 
        :nudge-right="40" 
        transition="scale-transition" 
        max-width="290px"
        min-width="290px">
        <template v-slot:activator="{ on }">
            <v-text-field v-mask="'##.##.####'"
            :rules="rules ? [...rules, (v) => checkDate(v)] : [(v) => checkDate(v)]"
            v-model="localValue"
            filled 
            outlined
            :label="label"
            @click:prepend-inner="on.click"  
            prepend-inner-icon="mdi-calendar-month-outline">
            </v-text-field>
        </template>
        <v-date-picker 
            locale='ru-Ru' 
            @change="handleChangeDate" 
            :value="formatValue" 
            :min="minDate" 
            :max="maxDate">
        </v-date-picker>
    </v-menu>  
</template>

<script>
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat)

export default {
  name: "DateField",
  props: ['value', 'label', 'minDate', 'maxDate', 'rules'],
  data() {
    return {
      localValue: this.value ? dayjs(this.value).format('DD.MM.YYYY') : '',
      menuOpen: false,
    };
  },
  methods: {
    handleChangeDate(d) {
        const date = dayjs(d).format('DD.MM.YYYY');
        this.localValue = date;  
        this.menuOpen = false;      
    },
    checkDate(dateString) {
        if(!dateString.length) return true
        const customDayjsDate = dayjs(dateString, 'DD.MM.YYYY', true);
        const valid = customDayjsDate.isValid();
        if(!valid) return 'Введите корректную дату'
        if(this.maxDate) {
          const isMoreThenMax = dayjs(customDayjsDate).isAfter(this.maxDate)
          if(isMoreThenMax) return 'Дата выше максимальной'
        }
        if(this.minDate) {
          const isLowThenMin = dayjs(customDayjsDate).isBefore(this.minDate)
          if(isLowThenMin) return 'Дата ниже минимальной'
        }
        return true
    }
  },
  computed: {
    formatValue() {
        const customDayjsDate = dayjs(this.localValue, 'DD.MM.YYYY', true);
        return customDayjsDate.isValid() ? customDayjsDate.format('YYYY-MM-DD') : undefined
    }
  },
  watch: {
    localValue() {
        this.$emit('input', this.formatValue)
    }
  }
}
</script>